import React, { useContext, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Header from './common/Header';
import "./css/index.css";
import FindAPet from './FindAPet';
import Home from './Home';
import SignIn from './SignIn';
import config from "./assets/config";
const { base_url, header: { close_icon } } = config;
const baseUrl = window.location.domain;
const AnimalShelter = ({ close, hideClose, unlocked }) => {
  
  const { getState } = useContext(GameStateContext);
  const [signedIn, setSignedIn] = useState(unlocked || getState()?.animalShelterLoggedIn);
  const [displayCaseHistory, toggleCaseHistory] = useState(false);
  return (
    <Router basename={baseUrl}>
      <Header close={close} hideClose={hideClose} {...{ signedIn, setSignedIn }} />
      <Div100vh dontresize="true" className="root_container_as" >
        <Switch>
          <Route exact path={["/", "/home"]} >
            <div className={"vw-100"}><Home /></div>
          </Route>
          <Route path="/findapet" >
            <div className={"vw-100"}>
              <FindAPet />
            </div>
          </Route>
          <Route path="/signin">
            <div className={"vw-100"}>
              <SignIn {...{ signedIn, setSignedIn, displayCaseHistory, toggleCaseHistory }} />
            </div>
          </Route>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Div100vh>
      {displayCaseHistory ?
            <Container fluid className={"case_history_popup_container"}>
                <Col xs={10} lg={8} className={"case_history_popup"}>
                    <Row className={"case_history_popup_header"}>
                        <span>
                            RESCUE HISTORY: CASE # BCJ_821506_E15_2244
                        </span>
                        <img alt={'Close'} src={base_url + close_icon} onClick={() => toggleCaseHistory(false)} /></Row>
                    <Col className={"case_history"}>{case_history.map((hist, index) => {
                        return <Row key={"hist" + index}>{hist}<br /><br /></Row>
                    })}</Col>
                </Col>
            </Container>
            : null}
    </Router>)
};
export default AnimalShelter;
const case_history = [`RESCUE HISTORY: CASE # BCJ_821506_E15_2244
`, `
NAME: "TRÉSOR"
`, `
JAN 6: INITIAL INTAKE (REF CASE # BCJ_821506_E15_2244)
`, `
JAN 8: MEDICAL EXAM, DENTAL CLEANING
`, `
JAN 10: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
JAN 10: NOACWS REQUESTED NEW REFERENCE DUE TO CURRENT REFERENCE'S RELATION TO APPLICANT (CURTIS, B. - MOTHER OF APPLICANT)
`, `
JAN 11: APPLICATION DENIED BY P. JETER - APPLICANT IS ORIGINAL OWNER, DID NOT DISCLOSE TO NOACWS
`, `
JAN 12: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
JAN 12: APPLICATION DENIED BY P. JETER
`, `
JAN 14: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
JAN 14: APPLICATION DENIED BY D. WILLIAMS
`, `
JAN 15: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
JAN 15: APPLICATION DENIED BY P. JETER
`, `
JAN 16: THREATS MADE BY PHONE (TURNER, N.), NOPD CASE #9595-38HE5
`, `
JAN 18: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
JAN 18: APPLICATION DENIED BY O. WALSH
`, `
JAN 21: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
JAN 21: APPLICATION DENIED BY O. WALSH
`, `
JAN 24: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
JAN 24: APPLICATION DENIED BY P. JETER
`, `
JAN 27: THREATS MADE BY PHONE (TURNER, N.), NOPD CASE #9595-23JV7
`, `
JAN 30: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
JAN 30: APPLICATION DENIED BY E. PHELPS
`, `
FEB 5: TRESPASSER DETAINED (TURNER, N.), NOPD CASE #7943-P9H1
`, `
FEB 6: RESTRAINING ORDER ISSUED (TURNER, N.), NOPD CASE #7573-9KA8  
`, `
FEB 7: ADOPTION APPLICATION RECEIVED (TURNER, N.)
`, `
FEB 7: APPLICATION DENIED BY E. PHELPS
`, `
FEB 7: APPLICANT BLOCKED FROM ALL FUTURE APPLICATIONS, O. WALSH
`, `
FEB 10: TRÉSOR GROOMED, PHOTOGRAPHED, REPOSTED TO FRONT PAGE
`, `
FEB 13: APPLICATION RECEIVED (SMITH FAMILY)
`, `
FEB 15: ADOPTED (REF CASE # BCJ_887506_D11_3408)`]