const config = {
    base_url: window.location.origin,
    header: {
        menu_close: "/assets/hak11-threekingsball-menu-close.svg",
        menu_close_white: "/assets/hak11-threekingsball-menu-close-white.svg",
        menu_hamburger: "/assets/hak11-threekingsball-menu-hamburger.svg",
        menu_logo: "/assets/hak11-threekingsball-menu-logo.svg",
        menu_logo_mobile: "/assets/hak11-threekingsball-menu-logo-landscape.svg",
        menu_home_active: "/assets/hak11-threekingsball-menu-home-active.svg",
        menu_home_inactive: "/assets/hak11-threekingsball-menu-home-inactive.svg",
        menu_news_active: "/assets/hak11-threekingsball-menu-news-active.svg",
        menu_news_inactive: "/assets/hak11-threekingsball-menu-news-inactive.svg",
        menu_sponsors_active: "/assets/hak11-threekingsball-menu-sponsors-active.svg",
        menu_sponsors_inactive: "/assets/hak11-threekingsball-menu-sponsors-inactive.svg",
        menu_theball_active: "/assets/hak11-threekingsball-menu-theball-active.svg",
        menu_theball_inactive: "/assets/hak11-threekingsball-menu-theball-inactive.svg",
        menu_thekrewe_active: "/assets/hak11-threekingsball-menu-thekrewe-active.svg",
        menu_thekrewe_inactive: "/assets/hak11-threekingsball-menu-thekrewe-inactive.svg",
        menu_whoweare_active: "/assets/hak11-threekingsball-menu-whoweare-active.svg",
        menu_whoweare_inactive: "/assets/hak11-threekingsball-menu-whoweare-inactive.svg",
    },
    home: {
        bg_fleurdelis_blue: "/assets/hak11-threekingsball-bg-fleurdelis-blue.svg",
        photo_homepage: "/assets/hak11-threekingsball-photo-homepage.png"
    },
    theball: {
        bg_fleurdelis_bgwhite: "/assets/hak11-threekingsball-bg-fleurdelis-bgwhite.svg",
        photo_bayouballadiers: "/assets/hak11-threekingsball-photo-bayouballadiers.png",
        photo_catering: "/assets/hak11-threekingsball-photo-catering.png",
        photo_cocktailzydecoorchestra: "/assets/hak11-threekingsball-photo-cocktailzydecoorchestra.png",
        photo_lapetitmortsextet: "/assets/hak11-threekingsball-photo-lapetitmortsextet.png",
    },
    common: {
        element_gold_divider: "/assets/hak11-threekingsball-element-gold-divider.svg"
    },
    whoweare: {
        photo_nolakidsartfoundation: "/assets/hak11-threekingsball-photo-nolakidsartfoundation.png",
    },
    thekrews: {
        thekrews_dulysse: "/assets/hak11-threekingsball-logo-krew-dulysse.svg",
        thekrews_barksalot: "/assets/hak11-threekingsball-logo-krewe-barksalot.svg",
        thekrews_piratebros: "/assets/hak11-threekingsball-logo-krewe-piratebros.svg",
        thekrews_vagabonds: "/assets/hak11-threekingsball-logo-krewe-vagabonds.svg",
        thekrews_versailles: "/assets/hak11-threekingsball-logo-krewe-versailles.svg",
        thekrews_mystic: "/assets/hak11-threekingsball-logo-mystic-krewe.svg",
        thekrews_touslesboufons: "/assets/hak11-threekingsball-logo-tous-lesboufons.svg",
    },
    sponsors: {
        sponsors_fortier_foods: "/assets/hak11-threekingsball-logo-fortier-foods.svg",
        sponsors_gonow_mag: "/assets/hak11-threekingsball-logo-gonow-mag.svg",
        sponsors_pelican_dentalcenter: "/assets/hak11-threekingsball-logo-pelican-dentalcenter.svg",
        sponsors_royalstreet_social: "/assets/hak11-threekingsball-logo-royalstreet-social.svg",
        sponsors_whodat_crispybird: "/assets/hak11-threekingsball-logo-whodat-crispybird.svg",
        photo_whodatcrispy: "/assets/hak11-threekingsball-photo-whodatcrispy.png"
    }
}
export default config