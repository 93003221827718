import React from 'react'
import Desktop from '../desktop/Desktop';
import { Header } from './ModalHeader';
import Draggable from 'react-draggable'
import './viewer.css';
const Viewer = ({ icon, close }) => {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle=".hak-modal-header-label" bounds={"#draggable-bounds"}>
            <div ref={nodeRef} className={"hak-modal-folder"} id={"folder-bounds"}>
                <Header icon={icon} close={close} />
                <Desktop config={icon.children} />
            </div>
        </Draggable>
    )
}
export default Viewer;