import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './css/home.css'
import config from './assets/config';
const { base_url, home: { bg_fleurdelis_blue, photo_homepage } } = config;
const Home = (props) => {
    return (<Container fluid className={"home_container"} style={{ backgroundImage: `url(${base_url + bg_fleurdelis_blue})` }}>
        <Row className="home_title rosella_solid">
            <Col xs={8} lg={10}  > Ladies and Gentlemen, Kings and Queens… <br />
                we are proud to announce...
            </Col>
        </Row>
        <Row className="home_banner">
            <Col xs={11} md={10} lg={9}>
                <img alt={'Banner'} className={"home_page_image"} src={base_url + photo_homepage} />
            </Col>
        </Row>
        <Row className="home_description">
            <Col xs={10}>
                This year’s Three Kings Ball will take place on January 6th at the
                Clem Duggins Grand Ballroom! Tickets can be purchased from
                any member of the participating krewes. Get ‘em while they last!
            </Col>
        </Row>
    </Container>)
}
export default Home;