import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Divider from './common/Divider';
import './css/theball.css'
import config from './assets/config';
const { base_url, home: { bg_fleurdelis_blue }, theball: { bg_fleurdelis_bgwhite,
    photo_bayouballadiers,
    photo_catering,
    photo_cocktailzydecoorchestra,
    photo_lapetitmortsextet } } = config;
const eventSchedule = [
    { time: "6:45 PM", event: "Doors open", desc: `(live music by the Cocktail Zydeco Orchestra)` },
    { time: "7:00–11:00", event: "Open bar" },
    { time: "7:00–10:00", event: "Blind auction of “Carnival Curiosities”", desc: `(opening remarks by Krewe D’Ulysse captain Jeremy Bacon)` },
    { time: "10:00–11:00", event: "Live music by the Bayou Balladiers" },
    { time: "10:00–11:00", event: "Dinner service by Chef Geraldine Watkins-Blanc" },
    { time: "11:00–12:00", event: "Live music by Le Petit Mort Sextet" }
]
const TheBall = (props) => {
    return (<Container fluid className={"theball_container"} style={{ backgroundImage: `url(${base_url + bg_fleurdelis_bgwhite})` }}>
        <Row className="theball_title rosella_solid">
            <Col xs={10}>
                The Blind Auction
            </Col>
        </Row>
        <Divider  xs={6} />
        <Row className="theball_statement">
            <Col xs={10}>
                At our blind auction, you’ll find Carnival collectibles and antiquities from
                the collections of dozens of Mardi Gras historians. All bidding is done in
                silence and in secret.
            </Col>
        </Row>
        <Row className="theball_title rosella_solid" >
            <Col xs={10}>
                Musical performances by
            </Col>
        </Row>
        <Row className="theball_image_group">
            <Col xs={8} lg={5}>
                <img alt={'Cocktail Decor'} className={"theball_image"} src={base_url + photo_cocktailzydecoorchestra} />
            </Col>
            <Col xs={8} lg={5} className={"theball_image_title"}>
                The Cocktail Zydeco Orchestra
                <Divider lg={6} />
            </Col>
        </Row>
        <Row className="theball_image_group">
            <Col xs={8} lg={5}>
                <img alt={'Bayou Balladiers'} className={"theball_image"} src={base_url + photo_bayouballadiers} />
            </Col>
            <Col xs={8} lg={5} className={"theball_image_title"}>
                The Bayou Balladiers
                <Divider lg={6} />
            </Col>
        </Row>
        <Row className="theball_image_group">
            <Col xs={8} lg={5}>
                <img alt={'Le Petit Mort Sextet'} className={"theball_image"} src={base_url + photo_lapetitmortsextet} />
            </Col>
            <Col xs={8} lg={5} className={"theball_image_title"}>
                Le Petit Mort Sextet
                <Divider lg={6} />
            </Col>
        </Row>
        <Row className="theball_image_group theball_chef_image_bg" style={{ backgroundImage: `url(${base_url + bg_fleurdelis_blue})` }}>
            <Col xs={5} lg={5}>
                <img alt={'CATERING'} className={"theball_image"} src={base_url + photo_catering} />
            </Col>
            <Col xs={5} lg={5} className={"theball_image_title text-white my-4 text-center"}>
                CATERING
                <Divider lg={6} />
                Elevated Creole and Cajun<br />
                specialities provided by
                <span className={"theball_chef_image_title"}>Chef Geraldine Watkins-Blanc</span>
                of Bistrot AVEC NOUS
            </Col>
        </Row>
        <Row className="theball_title rosella_solid">
            <Col lg={10}>
                Schedule of Events
            </Col>
        </Row>
        <Divider xs={6}/>
        <Row className={'mb-5'}>
            <Col lg={10}>
                {eventSchedule.map((event, index) => {
                    return <Row className={'mb-3'} key={"event_" + index}>
                        <Col xs={5} lg={6} className={"event_time"}>{event.time}</Col>
                        <Col xs={7} lg={6} className={"event_name"}>
                            {event.event}
                            <br />
                            <span className={"event_desc"}>{event.desc}</span>
                        </Col>
                    </Row>
                })}
            </Col>
        </Row>
    </Container>)
}
export default TheBall;