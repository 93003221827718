import React, { useState, useEffect, useContext } from 'react'
import { GameStateContext } from '../../context/context.jsx';
import { useWindowSize } from '../../_shared/utils';
import { Button } from 'react-bootstrap'
import Header from '../contact/Header';
import './viewer.css';
import Draggable from 'react-draggable'

const PDFViewer = ({ vumail, icon, close, openContact, parent }) => {
  const [loading, setLoading] = useState(true);
  const imageLoaded = () => setLoading(false);
  const context = useContext(GameStateContext);
  let { episode } = context?.getState();
  const nodeRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const [current, setCurrent] = useState(0);
  const backClick = () => { 
    current > 0 && setCurrent(current - 1); 
    let imgContainer = imgRef?.current;
    imgContainer.scrollTop = 0;
  }
  const fwClick = () => { 
    current < icon.url.length - 1 && setCurrent(current + 1); 
    let imgContainer = imgRef?.current;
    imgContainer.scrollTop = 0;
  }
  const [imgWidth, setImgWidth] = useState(100)
  const [fetching, setFetching] = useState(false)
  
  const zoom = (zoomIn) => {
    let widthPercent = imgWidth;
    widthPercent = zoomIn ? Math.max(widthPercent - 20, 25) : Math.min(widthPercent + 20, 350);
    setImgWidth(widthPercent);
  };
  const size = useWindowSize();
  const marginTop = icon?.top ? "0%" : "5%" ;
  const marginLeft = icon?.top ? "2%" : "0%";
  const marginBottom = icon?.top ? "7%" : "2%"
  const download = (url, name, e) => {
    e.preventDefault();
    if(!fetching){
        setFetching(true)
        fetch(url+"?crossorigin")
          .then(response => response.blob())
          .then(blob => {
            setFetching(false)
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";
            if (name && name.length) a.download = name + (icon.url.length > 1 ? "-" + (parseInt(current) + 1) : "");
            document.body.appendChild(a);
            a.click();
          })
          .catch(() => this.setState({error:true}));
        }
    
  };
  return <>
    <Draggable
      nodeRef={nodeRef}
      handle={icon.child ? ".hak-modal-header-label-secondlevel" : ".hak-modal-header-label"}
      bounds={icon.child ? "#folder-bounds" : "#draggable-bounds"}>
      <div ref={nodeRef} className="hak-image-box" style={{top: marginTop, left: marginLeft, bottom: marginBottom}}>
        <div className="hak-image-row header">
          <Header vumail={vumail} icon={icon} close={close} parent={parent} secondLevel={Boolean(icon.child)} />
        </div>
        <div ref={imgRef} className="hak-image-row content" style={{ height: icon?.vh ? icon.vh + "vh" : "84vh" }}>
          <img
            style={{ width: imgWidth + "%", display: loading ? 'none' : 'block' }}
            onLoad={imageLoaded}
            src={icon.url[current]}
            alt={"File not available!"}
          />
        
          {icon?.goodFences?.includes(parseInt(episode)) && current === 10 && 
          <div onClick={openContact} style={{
            
            cursor: "pointer",
            top: (0.40*size.width + 4.5), 
            left: (0.27*size.width - 7.57), 
            width: 0.3*size.width, 
            height: 0.04*size.width }}>
          </div>}
          {icon?.goodFences?.includes(parseInt(episode)) && current === 7 && 
          <div onClick={openContact} style={{
            
            cursor: "pointer",
            top: (0.40*size.width + 7), 
            left: (0.18*size.width - 7.57), 
            width: 0.3*size.width, 
            height: 0.04*size.width }}>
          </div> }
          {icon?.goodFences?.includes(parseInt(episode)) && current === 2 &&
            <div  onClick={openContact} style={{
             
              cursor: "pointer",
              top: (0.41*size.width + 7), 
              left: (0.57*size.width - 7.57), 
              width: 0.3*size.width, 
              height: 0.04*size.width }}>
            </div>
          }
        </div>
        <div className="hak-image-row footer">
        <div className="d-flex justify-content-between p-1">
            {icon.url.length > 1 && <div style={{borderBottom: "0px"}}>
              <button className={"btn btn-sm btn-dark"} onClick={backClick}>{" < "}</button>
              <span className={"p-2 mt-3"}>{"page: "}{current + 1}{"/"}{icon.url.length}</span>
              <button className={"btn btn-sm btn-dark"} onClick={fwClick}>{" > "}</button>
            </div>}
           
            <div style={{borderBottom: "0px"}}>
              <button disabled={imgWidth >= 350} className={"btn btn-sm"} onClick={() => zoom(false)}><img alt={'Zoom In'} style={{ width: "20px" }} src="https://cdn.huntakiller.com/huntakiller/s10/zoomin-icon.svg" /></button>
              <button disabled={imgWidth <= 25} className={"btn btn-sm"} onClick={() => zoom(true)}><img alt={'Zoom Out'} style={{ width: "20px" }} src="https://cdn.huntakiller.com/huntakiller/s10/zoomout-icon.svg" /></button>
              <button className={"btn btn-sm"} onClick={(e) => download(icon.url[current], icon.label, e)}><img alt={'Download'} style={{ width: "17px" }} src="https://cdn.huntakiller.com/huntakiller/s10/download-icon.svg" /></button>
            </div>
          </div>
          
        </div>
      </div>
    </Draggable>
  </>
}
export default PDFViewer;