import React, { useContext, useEffect, useState, useRef } from 'react';
import config from "./assets/config";
import "./css/signin.css";
import { Container, Row, Col } from 'react-bootstrap';

const { base_url, signin: {
    signin_herophoto
} } = config;
const VALID_USERNAME = process.env.REACT_APP_ANIMAL_SHELTER_USERNAME;
const VALID_PASSWORD = process.env.REACT_APP_ANIMAL_SHELTER_PASSWORD;
const case_details = [
    { label: 'Pet Name', text: 'TRÉSOR' },
    { label: 'Previous Owner', text: 'SURRENDER FACILITATED BY NOPD 9TH DISTRICT' },
    { label: 'Siblings or Bonded Pair', text: 'SURRENDERED WITH LARGE NUMBER OF OTHERS FROM SAME HOUSEHOLD' },
    { label: 'Rescued', text: 'JAN 6' },
    { label: 'Medical Status', text: 'HEALTHY' },
    { label: 'Neutered', text: 'YES' },
    { label: 'Fully Immunized?', text: 'YES' },
    { label: 'Status', text: 'ADOPTED' },
    { label: 'Current Owner/Location', text: 'The Smith Family (Covington, LA)' },
    { label: 'Approved for Foster Care', text: 'YES' },
]
const SignIn = (props) => {
    const { signedIn, setSignedIn, toggleCaseHistory } = props;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const signIn = useRef();
    // const [] = useState(false);
    const { saveAnimalShelterLoginStatus } = useContext(GameStateContext);
    useEffect(() => {
        if (window.screen.width < 992) {
            signIn.current?.scrollIntoView();
        }
    }, [])
    useEffect(() => {
        error && setError("");
    }, [username, password, error]);
    const validateLogin = () => {      
        if (username.toUpperCase() !== VALID_USERNAME || password.toUpperCase() !== VALID_PASSWORD)
            setError("Invalid Response");
        else {
            window?.ReactNativeWebView?.postMessage('animal_shelter_unlock')
            setSignedIn(true);
            saveAnimalShelterLoginStatus(true);
            setUsername("");
            setPassword("");
        }
    }
    return (<>
        {signedIn ?
            <Container fluid className={"signedin_container"}>
                <Row className={"signin_header_bar"} />
                <Row className={"case_header"}>
                    <Col lg={7} className={"case_num_container"}>
                        <Row className={"case_num"}>
                            CASE # BCJ_821506_E15_2244 "TRÉSOR"
                        </Row>
                        <Row className={"case_num_text"}>
                            NOTE: This case has been flagged by the New Orleans Police Department
                        </Row>
                    </Col>
                    <Col lg={5}><input
                        className={"case_num_input"}
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        placeholder={"Enter a Case Number"} />
                    </Col>
                </Row>
                <Row className={"signin_footer_bar"} />
                <Row className={"case_details"}>
                    {case_details.map(({ label, text }, index) => {
                        return <Row key={index} className={"case_detail_row"}>
                            <Col lg={3}>{label}:</Col>
                            <Col lg={9}>{text}</Col>
                        </Row>
                    })}
                    <button
                        className={"case_history_btn"}
                        onClick={() => toggleCaseHistory(true)}>
                        Case History
                    </button>
                </Row>
                <Row>
                </Row>
            </Container> :
            <Container fluid className={"signin_container "}>
                <Row className={"signin_header_bar"} />
                <Row>
                    <Col lg={8} className={"signin_image_container"} >
                        <img alt={'Sign In Hero'} className={"signin_image"} src={base_url + signin_herophoto} />
                    </Col>
                    <Col lg={4}>
                        <Row className={"signin_text"}>
                            NOTE: Only registered users
                            can use this function
                        </Row>
                        <Row>
                            <input
                                className={"signin_input"}
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                placeholder={"Enter your username"} />
                        </Row>
                        <Row>
                            <input
                                type={"password"}
                                className={"signin_input"}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder={"Enter your password"} />
                        </Row>
                        <Row className={"btn_container"} ref={signIn}>
                            <span>
                                {error}
                            </span>
                            <button
                                className={"sign_in_btn"}
                                onClick={validateLogin}>
                                SIGN IN
                            </button>
                        </Row>
                    </Col>
                </Row >
                <Row className={"signin_footer_bar"} />
            </Container >}
    </>)
}
export default SignIn;