import React from 'react';
import { Container } from 'react-bootstrap';
import config from "./assets/config";
import "./css/home.css";

const { base_url, home: { home_banner } } = config;
const Home = (props) => {
    return <Container fluid className={"home_container_as"}>
        <img alt={'Banner'} className={"home_banner"} src={base_url + home_banner} />
        <div className={"home_statement"}>
            Headquartered in Pines Village, the New Orleans Animal Control and Welfare Society
            has cared for animals and their people for over 50 years. If you know of an animal in
            need of assistance or would like to adopt one of our rescues, reach out or come by
            anytime between 7am and 8pm.
        </div>
    </Container>
}
export default Home;