import React, { useContext, useState } from 'react';
import { Row } from 'react-bootstrap';
import './desktop.css';
import { GameStateContext } from '../../context/context.jsx';
import { getComponentTag } from '../../_shared/utils.jsx';

const Desktop = ( { config, showContact  }) => {
    const [current, setCurrent] = useState(null);
    const [fullScreen, setFullScreen] = useState(false)
    const context = useContext(GameStateContext);
    const close = () => {
        setCurrent(null);
        setFullScreen(false)
    }
    const iconClick = (icon) => {
        setCurrent(icon);
        if(icon.fullScreen) {
            setFullScreen(true)
        }
    }
    const { episode, cta, episodeEnd, ep6GoodGuyUnlock } = context?.getState()
    const getIcon = (icon) => {
        if ((icon.label === "Pawn Shop Photo" && ep6GoodGuyUnlock) || (icon.unlockAt <= (parseInt(episode)) + parseFloat(cta/10) + (episodeEnd ? 0.1 : 0) )) {
                return <li key={icon.label} className="col" >
                <div className="pt-3 pb-2 hak-icon">
                    <img onClick={() => iconClick(icon)} src={icon.img} alt={icon.label} />
                </div>
            </li>
        }
    }
    //this is only called from the good fences open contact links 
    const openContact = () => {
        setFullScreen(true)
        setCurrent({...contactIcon, goodFences:true})
    }
    const contactIcon = {
        type: "contact",
        img: "https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-contact-icon.svg",
        fullScreen: true
    }
    return  <>
            {!fullScreen ? 
                <>
                <div className={current?.type === "folder" ? "row-cols-sm-6" : "row-cols-sm-2"}>
                    <ul className="row row-cols-4 row-cols-sm-4 row-cols-lg-4 row-cols-xl-6 list-unstyled list hak-icon-ul"  >
                        {config.map((icon) => (
                            getIcon (icon) 
                        ))}
                        <li>{current && getComponentTag(current, close, openContact)}</li>
                    </ul>
                    
                </div>
                {showContact && <div className="hak-contact-icon">
                    <img alt={'Contact'} onClick={() => iconClick(contactIcon)} src={"https://cdn.huntakiller.com/huntakiller/s11/desktop/hak11-desktop-contact-icon.svg"} />
                </div>}
                </>
            : 
            current && getComponentTag(current, close, openContact)} 
    </>
};
export default Desktop;