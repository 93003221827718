import React from 'react';
import { Container, Col, Row } from 'react-bootstrap'
import config from "./assets/config";
import "./css/findapet.css";

const { base_url, findapet: {
    findapet_porter,
    findapet_ziggy,
    findapet_neville,
    findapet_clementine,
} } = config;
const FindAPet = (props) => {
    return (<Container fluid className={"findapet_container"}>
        <Row className={"findapet_mobile_title"}>
            Find a Pet
        </Row>
        <Row className={"findapet_header_bar"} />
        <Row className={"findapet_title"}>
            Meet our Newest Adoptables!
        </Row>
        <Row className={"flex-wrap"}>
            <Col lg={6} className={"pet_container"}>
                <img alt={'Porter'} className={"pet_image"} src={base_url + findapet_porter} />
            </Col>
            <Col lg={6} className={"pet_container"}>
                <img alt={'Ziggy'} className={"pet_image"} src={base_url + findapet_ziggy} />
            </Col>
            <Col lg={6} className={"pet_container"}>
                <img alt={'Neville'} className={"pet_image"} src={base_url + findapet_neville} />
            </Col>
            <Col lg={6} className={"pet_container"}>
                <img alt={'Clementine'} className={"pet_image"} src={base_url + findapet_clementine} />
            </Col>
        </Row>
        <Row className={"findapet_footer_bar"} />
        <Row className={"findapet_statement"}>
            <Col lg={8} md={12} sm={12}>
                Come by in person to meet the rest of our rescues and
                give one of our animals a furever home.
            </Col>
        </Row>
    </Container>)
}
export default FindAPet;