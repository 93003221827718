import React, { useState, useContext, useEffect } from 'react'
import Header from './Header';
import LeftNav from './LeftNav'
import MailsList from './MailsList'
import ViewMail from './ViewMail'
import NewMail from './NewMail'
import { Row, Col, Spinner } from 'react-bootstrap'
import { qna, views, prettyDate } from '../../_shared/utils'
import { mailConfig, mailAttachments, mailNoMatch } from '../../_shared/config'
import { useMediaQuery } from '../../_shared/utils'
import { GameStateContext } from '../../context/context.jsx'
import './contact.css'
const Contact = ({ icon, close }) => {
    const goodFences = icon?.goodFences;
    const vh = goodFences ? 70 : 74; //vertical height of image viewer 
    let small = useMediaQuery('(max-width: 767px)')
    const context = useContext(GameStateContext);
    const defaultView = goodFences ? views.NEW : views.INBOX
    
    let { episode, cta, chat, goodFencesChat } = context?.getState()
    const key = parseInt(episode) + cta/10
    const recepient = goodFences ? mailConfig.goodFences[episode].toFrom : mailConfig.toFrom; 
    const subject = goodFences ? mailConfig.goodFences[episode].toSubject : mailConfig[key];
    const toBody = goodFences ? mailConfig.goodFences[episode].toBody : "";
    const [activeView, setActiveView] = useState(defaultView)
    const [activeMail, setActiveMail] = useState()
    const [showMenu, setshowMenu] = useState(true)
    const no_good_match = "no good match found in kb.";
    const [showSpinner, setShowSpinner] = useState(false)
    const currentUserEmail = 'player@hakdetective.com'

    const [mails, setEmailsList] = useState((goodFences ? goodFencesChat : chat) || [])
    const toggleMenu = () => {
        setshowMenu(!showMenu)
    }
    const showMail = (mail) => {
        setActiveView(views.MAIL)
        setActiveMail(mail)
        setshowMenu(false)
    }
    const setMails = (mails) => {
        setEmailsList(mails)
        goodFences ? context.updateStateGoodFencesChatOnly(mails) : context.updateStateChatOnly(mails)
    }
    const setViewed = (id) => {
        let mailsCopy = [...mails]
        let markViewed = mailsCopy.find(m => m.id === id)
        if (markViewed && !markViewed.viewed) {
            markViewed.viewed = true;
            setMails([...mailsCopy.filter(m => m.id !== id), { ...markViewed }])
        }
    }
    const sendMail = async (mail) => {
        if(!key) return;
        let incoming = {
            type: views.INBOX,
            sender: recepient,
            to: currentUserEmail,
            dateTime: prettyDate(),
            viewed: false
        }
        if(goodFences){
            incoming.subject = mailConfig.goodFences[episode].fromSubject;
            incoming.body = mailConfig.goodFences[episode].fromBody;
            incoming.attachments = mailConfig.goodFences[episode].attachments;
            if(parseInt(episode) === 6) {
                context.saveEp6GoodGuyUnlock(true)
            }
        }
        else{
            incoming.subject = subject
            let playerResponse = mail.body;
            let skipQna = false;
            let chat = no_good_match;
            //e2,c2 
            //strip out everything except the numbers so we can get a proper match in qna maker
            if(parseInt(episode) === 3 && parseInt(cta) === 1 ){
                let timeline = playerResponse.match(/\d/g);
                playerResponse = timeline?.join("");
                if(!playerResponse) { skipQna = true}
            }
            //e2,c1 skip qna if not 100% match
            if(parseInt(episode) === 2 && parseInt(cta) === 1 ){
                if(playerResponse.toLowerCase() !== "rougarou"){
                    skipQna = true;
                }
            }
            if(!skipQna)
            {   
                //1 - talk to bot and add reply to inbox
                let response = await qna(playerResponse, episode, cta)
                const data = await response.json();
                chat = data?.answer || no_good_match;    
            }
            //1. no answer found
            if (chat.toLowerCase() === no_good_match) {
                incoming.body = mailNoMatch[key]
            }
            else {
                const isCorrect = chat.indexOf("{{Correct}}") > -1;
                // Replace newlines and remove Correct/Incorrect markers
                incoming.body = chat
                    .replace("{{Correct}}", "")
                    .replace("{{Incorrect}}", "")
                    .replace(/\\n/g, '\n');
                
                //2. if is correct, add attachments 
                if (isCorrect) {
                    incoming.attachments = mailAttachments[key]
                   
                    if(parseInt(cta) === 1){
                        context.updateStateCtaOnly(2) 
                    }
                    else if(parseInt(cta) === 2) { 
                        context.setEpisodeEnd()
                        // sendKlaviyoEvent(episode,currentUserEmail);
                        const episodeInt = "0"+parseInt(episode);
                        var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"hak11", "episode":episodeInt, "success": "hak110{episodeInt}"} });
                        window.dispatchEvent(event);
                    }
                }
            }
        }
        
        setShowSpinner(true)
        // add item to sent items list
        setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, {...incoming, id: Date.now() + 1000}])
        setTimeout(()=>{
            setShowSpinner(false)
            setActiveView(views.INBOX)
        }, 750)
    }
    const back = (view) => {
        setActiveView(view)
    }
    const leftNavSelect = (val) => {
        setActiveView(val)
        setshowMenu(false)
    }
    
    useEffect(() => {
        if(small){
            leftNavSelect(views.NEW)
        }
    },[small])
    const trashEmail = (mail) => {
        setShowSpinner(true)
        
        let mailsCopy = [...mails]
        let toTrash = mailsCopy.find(m => m.id === mail.id)
        toTrash.type = views.TRASH;
        setMails([...mailsCopy.filter(m => m.id !== mail.id), { ...toTrash }])
        setActiveView(views.TRASH);
        setTimeout(()=>{
            setShowSpinner(false)
        }, 500)
        
    }
    const viewHeight = "37vh";
    const listHeight = "80vh";
    return <div className={goodFences ? "contactContainerInsideFolder" : "contactContainer"} style={{ marginTop: "0px", marginRight: "0px", backgroundColor: "white", overflow: "scroll"  }}>
        <Header close={close} toggleNav={toggleMenu} />
        <Row style={{ backgroundColor: "white", margin: "0px" }}>
            <Col sm={12} md={3} lg={2} >
                {((small && showMenu) || !small) && <LeftNav activeView={activeView} setActive={leftNavSelect} />}
            </Col>
            {showSpinner && <Col className={"spinnerContainer"} sm={12} md={9} lg={10} style={{ borderLeft: "1px dotted lightgray" }} >
                <Spinner className={"spinnerInside"} variant="secondary" animation="border" />
            </Col>}
            {!showSpinner && <Col sm={12} md={9} lg={10} style={{ borderLeft: "1px dotted lightgray" }}>
                {((small && !showMenu) || !small) && <div>
                    {activeView === views.INBOX && <MailsList type={views.INBOX} mails={mails.filter(m => m.type === views.INBOX)} setActiveMail={showMail} height={listHeight} />} 
                    {activeView === views.SENT && <MailsList type={views.SENT} mails={mails.filter(m => m.type === views.SENT)} setActiveMail={showMail} height={listHeight}/>}
                    {activeView === views.TRASH && <MailsList type={views.TRASH} mails={mails.filter(m => m.type === views.TRASH)} setActiveMail={showMail} height={listHeight} />} 
                    
                    {activeView === views.NEW && <NewMail sendMail={sendMail} back={back} recepient={recepient} sender={currentUserEmail} toBody={toBody} subject={subject} height={viewHeight}/>}
                    {activeView === views.MAIL && <ViewMail vh={vh} mail={activeMail} back={back} trash={trashEmail} height={viewHeight} setViewed={setViewed} />}
                </div>}
            </Col>}
        </Row>
    </div>
}
export default Contact