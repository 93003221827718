import React, { useEffect, useState } from 'react';
import '../css/header.css'
import config from '../assets/config';
const { base_url, header } = config;
const Header = ({ hideClose, close }) => {
    const history = useHistory();
    const currentPage = useLocation().pathname.replace('/', '') || "home";
    const [displayMobileMenu, toggleMenu] = useState(false);
    const handleMenuClick = (page, closeMenu = false) => {
        history.push(page);
        toggleMenu(!closeMenu);
    }
    
    useEffect(() => {
        document.getElementsByClassName('root_container')?.length > 0 && document.getElementsByClassName('root_container')[0].scrollTo(0, 0)
    }, [currentPage]);
    return (<>
        <Container fluid className={"m-0 p-0 header-container"}>
            <Row>
                <Col className={"menu_items"}>
                    <img alt={'menu_home'} onClick={() => handleMenuClick("home")} className={`menu_image ${currentPage === "home" ? "menu_active" : ""}`} src={base_url + header[`menu_home_${currentPage === "home" ? "active" : "inactive"}`]}></img>
                    <img alt={'News'} onClick={() => handleMenuClick("news")} className={`menu_image ${currentPage === "news" ? "menu_active" : ""}`} src={base_url + header[`menu_news_${currentPage === "news" ? "active" : "inactive"}`]}></img>
                    <img alt={'Who We Are'} onClick={() => handleMenuClick("whoweare")} className={`menu_image ${currentPage === "whoweare" ? "menu_active" : ""}`} src={base_url + header[`menu_whoweare_${currentPage === "whoweare" ? "active" : "inactive"}`]}></img>
                </Col>
                <div className={"logo_image_container"}>
                    <img alt={'Menu Logo'} className={"logo_image"} src={base_url + header[`menu_logo`]}></img>
                </div>
                <Col className={"menu_items"}>
                    <img alt={'The Ball'} onClick={() => handleMenuClick("theball")} className={`menu_image ${currentPage === "theball" ? "menu_active" : ""}`} src={base_url + header[`menu_theball_${currentPage === "theball" ? "active" : "inactive"}`]}></img>
                    <img alt={'The Krewe'} onClick={() => handleMenuClick("thekrewe")} className={`menu_image ${currentPage === "thekrewe" ? "menu_active" : ""}`} src={base_url + header[`menu_thekrewe_${currentPage === "thekrewe" ? "active" : "inactive"}`]}></img>
                    <img alt={'Sponsors'} onClick={() => handleMenuClick("sponsors")} className={`menu_image ${currentPage === "sponsors" ? "menu_active" : ""}`} src={base_url + header[`menu_sponsors_${currentPage === "sponsors" ? "active" : "inactive"}`]}></img>
                </Col>
            </Row>
        </Container>
        <Container fluid className={"m-0 p-0 header-mobile"}>
            <Row className={"flex-wrap p-0 m-0 w-100 align-items-center justify-content-between h-100"}>
                <Col className={"header_icon_tk_container d-flex m-1 justify-content-center"}>
                    <img alt={'Menu'} className={"header_icon_tk"} onClick={() => toggleMenu(true)} src={base_url + header[`menu_hamburger`]} ></img>
                </Col>
                <img alt={'Logo'} className={"mobile_header_logo"} src={base_url + header.menu_logo_mobile} />
                <Col className={"header_icon_tk_container d-flex m-1 justify-content-center"}>
                    {<img alt={'Close'} className={"header_icon_tk"} style={{visibility: hideClose ? "hidden" : "visible"}} onClick={close} src={base_url + header[`menu_close`]} />}
                </Col>
            </Row>
        </Container>
        {displayMobileMenu ? <Container fluid className={"p-0 m-0 mobile_menu"}>
            <Row className={"flex-wrap p-0 m-0 w-100 align-items-center justify-content-between mobile_menu_header"}>
                <img alt={'menu_logo_mobile'} className={"mobile_header_logo"} src={base_url + header[`menu_logo_mobile`]} />
                <Col className={"header_icon_tk_container d-flex m-1 justify-content-center"}>
                    <img alt={'Menu Close'} className={"header_icon_tk"} onClick={() => toggleMenu(false)} src={base_url + header[`menu_close_white`]} />
                </Col>
            </Row>
            <Row className={"mobile_menu_items"}>
                <img alt={'Home'} onClick={() => handleMenuClick("home", true)} className={`mobile_menu_image ${currentPage === "home" ? "menu_active" : ""}`} src={base_url + header[`menu_home_${currentPage === "home" ? "active" : "inactive"}`]}></img>
                <img alt={'News'} onClick={() => handleMenuClick("news", true)} className={`mobile_menu_image ${currentPage === "news" ? "menu_active" : ""}`} src={base_url + header[`menu_news_${currentPage === "news" ? "active" : "inactive"}`]}></img>
                <img alt={'Who We Are'} onClick={() => handleMenuClick("whoweare", true)} className={`mobile_menu_image ${currentPage === "whoweare" ? "menu_active" : ""}`} src={base_url + header[`menu_whoweare_${currentPage === "whoweare" ? "active" : "inactive"}`]}></img>
                <img alt={'The Ball'} onClick={() => handleMenuClick("theball", true)} className={`mobile_menu_image ${currentPage === "theball" ? "menu_active" : ""}`} src={base_url + header[`menu_theball_${currentPage === "theball" ? "active" : "inactive"}`]}></img>
                <img alt={'The Krewe'} onClick={() => handleMenuClick("thekrewe", true)} className={`mobile_menu_image ${currentPage === "thekrewe" ? "menu_active" : ""}`} src={base_url + header[`menu_thekrewe_${currentPage === "thekrewe" ? "active" : "inactive"}`]}></img>
                <img alt={'Sponsors'} onClick={() => handleMenuClick("sponsors", true)} className={`mobile_menu_image ${currentPage === "sponsors" ? "menu_active" : ""}`} src={base_url + header[`menu_sponsors_${currentPage === "sponsors" ? "active" : "inactive"}`]}></img>
            </Row>
        </Container> : null
        }
        <img alt={'Close'} className={"close_icon"} onClick={close} src={base_url + header[`menu_close_white`]} />
    </>)
}
export default Header;