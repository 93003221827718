import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Div100vh from 'react-div-100vh';
import Files from './Files'
import Texts from './Texts'
import Login from './Login'
import './vu.css'

const VuMailDrive = ({ hideClose, close }) => {
  const [current, setCurrent] = useState("files")
 
  return <Div100vh dontresize="true" className="vh-100 vw-100" style={{ backgroundColor: "white", overflow: "scroll" }}>
    <Login close={close}>
      {/* header */}
      <div className="d-flex justify-content-between p-2 vuheader" >
        <img style={{ width: "5vh", marginRight: "25px" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-vumailicon.svg"} alt={"VuMail"} />
        <Row className={"vuheader-middle"}>
          <Col sm="9">
            <Row className="searchBox">
              <Col xs="2"><img style={{ width: "10px" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-searchicon.svg"} alt={"VuMail"} /></Col>
              <Col xs="8" style={{ }}>Search VuMail Drive</Col>
              <Col xs="2"><img style={{ width: "10px", float: "right" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-searcharrow.svg"} alt={"VuMail"} /></Col>
            </Row>
          </Col>
          <Col sm="3" >
            <Row className="d-flex justify-content-center">
              <Col xs="4"><img style={{ width: "20px", margin: "5px" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-uploadicon.svg"} alt={"VuMail"} /></Col>
              <Col xs="4"><img style={{ width: "20px", margin: "5px" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-viewicon.svg"} alt={"VuMail"} /></Col>
              <Col xs="4"><img style={{ width: "20px", margin: "5px" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-settingsicon.svg"} alt={"VuMail"} /></Col>
            </Row>
          </Col>
        </Row>
        <button onClick={close} type="button" style={{visibility: hideClose ? "hidden" : "visible", marginLeft: "10px", width: "10vh", border: "0px transparent", backgroundColor: "transparent" }}>
          <img style={{ width: "35px" }} src="https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-closebuttongray.svg"  alt="Close"></img>
        </button>
      </div>
      {/* header icons */}
      <div className="d-block d-md-none d-flex justify-content-between vuheader vuheadericons">
        <img style={{ height: "6vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-whitefoldericon.svg"} alt={"VuMail"} />
        <img style={{ height: "6vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-recenticon.svg"} alt={"VuMail"} />
        <img style={{ height: "6vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-starredicon.svg"} alt={"VuMail"} />
        <img style={{ height: "6vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-trashicon.svg"} alt={"VuMail"} />
        <img style={{ height: "6vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-storageicon.svg"} alt={"VuMail"} />
      </div>
      <div className="row belowHeader" >
        {/* leftnav */}
        <div className="d-none d-md-block col-md-3 vuheader" style={{ border: "1px solid #2E3191" }}>
          <div className="row pl-4" style={{ height: "5vh" }}></div>
          <div className="row">
            <img style={{width: "100%"}} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-fileshighlighted.svg"} alt={"VuMail"} />
          </div>
          <div className="row m-3">
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-recenticon.svg"} alt={"VuMail"} />
            <div style={{ padding: "10px" }}>Recent</div>
          </div>
          <div className="row m-3">
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-starredicon.svg"} alt={"VuMail"} />
            <div style={{ padding: "10px" }}>Starred</div>
          </div>
          <div className="row m-3">
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-trashicon.svg"} alt={"VuMail"} />
            <div style={{ padding: "10px" }}>Recycle</div>
          </div>
          <div className="row p-6 w-50" style={{ borderTop: "2px solid #2E3191" }}></div>
          <div className="row m-3">
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-storageicon.svg"} alt={"VuMail"} />
            <div style={{ padding: "10px" }}>Storage</div>
          </div>
        </div>
        {/* main content */}
        <div className="col-md-9 col-sm-12">
          {current === "files" && <Files setCurrent={setCurrent} />}
          {current === "texts" && <Texts setCurrent={setCurrent} />}
        </div>
      </div>
    </Login>
  </Div100vh>
};
export default VuMailDrive;