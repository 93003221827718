import React, { useState } from 'react';
import '../css/header.css'
import config from "../assets/config";
const { base_url, header } = config;
const Header = ({ signedIn, setSignedIn, close, hideClose }) => {
    const history = useHistory();
    const currentPage = useLocation().pathname.replace('/', '') || "home";
    const [displayMobileMenu, toggleMenu] = useState(false);
    const handleMenuClick = (page, closeMenu = false) => {
        history.push(page);
        toggleMenu(!closeMenu);
    }
    const handleBack = () => {
        setSignedIn(false);
        //disabled loggout
        //saveAnimalShelterLoginStatus(false);
    }
    const headerText = () => {
        switch (currentPage) {
            case "home": {
                return (<span>Compassion to the rescue</span>)
            }
            case "findapet": {
                return (<span>Find a Pet</span>)
            }
            case "signin": {
                return signedIn ? (<span style={{ cursor: "pointer" }} onClick={handleBack}>{"< Back"}</span>) : (<span>Sign In</span>)
            }
            default: {
                return (<span>Compassion to the rescue</span>)
            }
        }
    }
    return (<>
        <Container fluid className={"m-0 p-0 header_container"}>
            <Row className={"h-100 justify-content-between"}>
                <Col lg={6} className={"header_logo_container"}>
                    <img src={base_url + header.header_logo} alt={"header_logo"} className={"header_logo"} />
                </Col>
                <Col lg={6} className={"menu_container"}>
                    <Row className={"header_text"}>
                        {headerText()}
                        <img className={"close_icon_as"} alt={"close_icon_as"} onClick={close} src={base_url + header.close_icon}></img>
                    </Row>
                    <Row className={"d-flex align-items-center justify-content-end"}>
                        <Col xs={3} className={`menu_icon_container justify-content-start`}>
                            <img
                                 alt={'Menu Home Icon'}
                                className={`menu_icon`}
                                src={base_url + header[`menu_home_${currentPage === "home" || currentPage === "" ? 'active' : 'inactive'}`]}
                                onClick={() => handleMenuClick("home")} />
                        </Col>
                        <Col xs={3} className={`menu_icon_container justify-content-center`}>
                            <img
                                 alt={'Menu Find a Pet Icon'}
                                className={`menu_icon`}
                                src={base_url + header[`menu_findapet_${currentPage === "findapet" ? 'active' : 'inactive'}`]}
                                onClick={() => handleMenuClick("findapet")} />
                        </Col>
                        <Col xs={3} className={`menu_icon_container`}>
                            <img
                                 alt={'Menu Sign In Icon'}
                                className={`menu_icon`}
                                src={base_url + header[`menu_signin_${currentPage === "signin" ? 'active' : 'inactive'}`]}
                                onClick={() => handleMenuClick("signin")} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <Container fluid className={"m-0 p-0 header_container_mobile"}>
            <Row className={"p-0 m-0 w-100 align-items-center justify-content-between h-100"}>
                <Col xs={2} className={"header_icon_container d-flex justify-content-center"}>
                    <img alt={'Menu'} className={"header_icon"} onClick={() => toggleMenu(true)} src={base_url + header[`menu_hamburger`]} />
                </Col>
                <Col xs={8} className={"d-flex flex-column align-items-center justify-content-end pb-3 h-100"}>
                    <img alt={'Logo'}  className={"mobile_header_logo_as"} src={base_url + header[`header_logo`]} />
                    <span className={"mobile_header_text"}>Compassion to the rescue</span>
                </Col>
                <Col xs={2} className={"header_icon_container d-flex justify-content-center"}>
                    <img alt={'Close'}  style={{visibility: hideClose ? "hidden" : "visible"}} className={"header_icon"} onClick={close} src={base_url + header[`close_icon`]} />
                </Col>
            </Row>
        </Container>
        {displayMobileMenu ? <Container fluid className={"p-0 m-0 mobile_menu_as"}>
            <Row className={"flex-wrap p-0 m-0 w-100 align-items-center justify-content-between mobile_menu_as_header"}>
                <Col xs={9}>
                    <img alt={'Logo'} className={"mobile_header_logo_as"} src={base_url + header[`header_logo`]} />
                </Col>
                <Col xs={3} className={"header_icon_container d-flex m-1 justify-content-center"}>
                    <img alt={'Close'} className={"header_icon"} onClick={() => toggleMenu(false)} src={base_url + header[`close_icon`]} />
                </Col>
            </Row>
            <Row className={"mobile_menu_as_items"}>
                <img alt={'Home'} onClick={() => handleMenuClick("home", true)} className={`mobile_menu_as_image ${currentPage === "home" ? "menu_active_as" : ""}`} src={base_url + header[`menu_home_${currentPage === "home" ? "active" : "inactive"}`]}></img>
                <img alt={'Find A Pet'} onClick={() => handleMenuClick("findapet", true)} className={`mobile_menu_as_image ${currentPage === "findapet" ? "menu_active_as" : ""}`} src={base_url + header[`menu_findapet_${currentPage === "findapet" ? "active" : "inactive"}`]}></img>
                <img alt={'Sign In'} onClick={() => handleMenuClick("signin", true)} className={`mobile_menu_as_image ${currentPage === "signin" ? "menu_active_as" : ""}`} src={base_url + header[`menu_signin_${currentPage === "signin" ? "active" : "inactive"}`]}></img>
            </Row>
        </Container> : null
        }
    </>)
}
export default Header;