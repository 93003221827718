import React, { useState } from 'react'
import Header from '../contact/Header';
import './viewer.css';
import Draggable from 'react-draggable'

const Viewer = ({ icon, close }) => {
    const [txtContent] = useState()
    const nodeRef = React.useRef(null);
    return (
        <Draggable
            nodeRef={nodeRef} 
            handle={icon.child ? ".hak-modal-header-label-secondlevel" : ".hak-modal-header-label" }
            bounds={icon.child ? "#folder-bounds" : "#draggable-bounds"}>
            <div ref={nodeRef} className={"hak-modal-txt"}>
                <Header icon={icon} close={close} secondLevel={Boolean(icon.child)} />
                <div style={{ "overflowY": "auto", "height": "25em", "padding": "20px", "color": "black" }} dangerouslySetInnerHTML={{ __html: txtContent }}></div>
            </div>
        </Draggable>
    )
}
export default Viewer;