import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './css/sponsors.css'
import config from './assets/config';
const { base_url, sponsors: { photo_whodatcrispy, sponsors_fortier_foods, sponsors_gonow_mag, sponsors_pelican_dentalcenter, sponsors_royalstreet_social, sponsors_whodat_crispybird } } = config;
const sponsors = [sponsors_fortier_foods, sponsors_royalstreet_social, sponsors_whodat_crispybird, sponsors_gonow_mag, sponsors_pelican_dentalcenter];
const Sponsors = (props) => {
    return (<Container fluid className={"sponsors_container"}>
        <Row className="sponsors_title rosella_solid">
            <Col lg={10}>
                The Sponsors
            </Col>
        </Row>
        <Row className={"justify-content-center"}>
            {sponsors.map((svg_endpoint, index) => {
                return (<Col md={5} key={"image_" + index} lg={4} className={"sponsors_logo_container"} >
                    <img alt={'Sponsors Logo'} src={base_url + svg_endpoint} className={"sponsors_logo"} />
                </Col>)
            })}
        </Row>
        <Row className={"sponsors_ad_image_container"} >
            <img alt={'Sponsors Ad'} src={base_url + photo_whodatcrispy} align={"top"} className={"sponsors_ad_image"} />
        </Row>
    </Container>)
}
export default Sponsors;