import { useState} from 'react'
import Untitled from './Untitled'
import Image from './../../viewer/Image'
import { useMediaQuery } from '../../../_shared/utils'
import './vu.css'
const Files = ({setCurrent}) => {
    const [current, setCurrentFileState] = useState("")
    const imgRoot = "https://cdn.huntakiller.com/huntakiller/";
    const imgs = {
        "officer" : ["s11/media/officer_blake.jpg"],
        "hidden" : ["s11/media/hidden_nola.jpg"]
    }
    const closeCurrent = (parent) => {
        setCurrentFile("")
        setCurrent(parent)
    }
    const setCurrentFile = (current) => {
        setCurrentFileState(current)
        //imgs[current] && window?.ReactNativeWebView?.postMessage(imgs[current][0]);
    }
    let small = useMediaQuery('(max-width: 768px)')
    const getU = () => {
        return imgs[current].map(i=>(imgRoot+i))
    }
    return <div className="fileContainer" >
        
        {current === "untitled" && <Untitled closeCurrent={closeCurrent} parent={"files"}/>}
        {current === "officer" && <Image vumail={true} icon={ {vh: small ? 52: 67, url: getU(), label: "Officer Blake Email"}} close={closeCurrent} parent={"files"}/>}
        {current === "hidden" && <Image vumail={true} icon={ {vh: small? 52: 67, url: getU(), label: "Hidden Nola Blog"} } close={closeCurrent} parent={"files"}/>}
        <div className="row ml-4 mr-4 mt-3">
            <div className={"w-100"}><h3>Files</h3></div>
            <div>
                Name 
            </div>
        </div>
        <div style={{cursor: "pointer"}}  className="row m-4 mt-0" onClick={() => setCurrent("texts")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-foldericon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Texts</span>
        </div>
        <div style={{cursor: "pointer"}} className="row m-4" onClick={() => setCurrentFile("untitled")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-docicon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Untitled</span>
        </div>
        <div style={{cursor: "pointer"}} className="row m-4" onClick={() => setCurrentFile("officer")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-photoicon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Officer Blake Email</span>
        </div>
        <div style={{cursor: "pointer"}} className="row m-4" onClick={() => setCurrentFile("hidden")}>
            <img style={{ height: "7vh" }} src={"https://cdn.huntakiller.com/huntakiller/s11/vumail/hak11-vumail-photoicon.svg"} alt={"VuMail"} />
            <span style={{ padding: "10px" }}>Hidden Nola Blog</span>
        </div>
    </div>
}
export default Files;