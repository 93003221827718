import { useState, useEffect } from 'react';
import Audio from '../components/viewer/Audio';
import Folder from '../components/viewer/Folder';
import Image from '../components/viewer/Image'; //this can show images too
import Note from '../components/viewer/Note';
import AnimalShelter from '../components/websites/animalShelter'
import ThreeKings from '../components/websites/threeKings'
import VuMailDrive from '../components/websites/vuMailDrive'
import Contact from '../components/contact/Contact'
import Video from '../components/viewer/Video'
import base64 from 'base-64';

export const views = {
    INBOX: 'inbox',
    SENT: 'sent',
    TRASH: 'trash',
    NEW: 'new',
    MAIL: 'mail'
};

export const default_threshold_score = 80
export const passes = JSON.parse(import.meta.env.VITE_EP_PW);
const KLAVIYO_URL = "https://a.klaviyo.com/api/track?data="

export const getComponentTag = (icon, close, openContact) => {
  let components = {
    folder: Folder,
    txt: Note,
    audio: Audio,
    pdf: Image,
    web_animal_shelter: AnimalShelter,
    web_three_kings: ThreeKings,
    web_vumail_drive: VuMailDrive,
    contact: Contact,
    video: Video
  };
  if (icon.type === "external_link") {
    window.open(icon.url, '_blank');
  }
  else {
    const TagName = components[icon.type];
    return <TagName icon={icon} close={close} openContact={openContact} />
  }
}

export const qna = async (question, episode, cta) => {
    try {
        const episodeKey = "episode:" + (parseInt(episode) + cta/10).toString()
        const response = await fetch('https://chathak.netlify.app/api/qa', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ 
                question, 
                episode: episodeKey,
                cta: true,
                game: "HAK11"
            }),
        });
        return response;
    } catch (error) {
        console.error('Error in qna:', error);
        return { json: () => ({ answer: "I'm sorry, I couldn't process your request. Please try again." }) };
    }
}

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);
  return matches;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function prettyDate() {
  const today = new Date();
  let date = today.toISOString().slice(0, 10)
  let time = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  return date + " " + time
}

export function sendKlaviyoEvent(episode, userEmail) {
  const game_prefix = "HAK110";
  let data = {
    "token": process.env.REACT_APP_KLAVIYO_TOKEN || 'QLKvDc',
    "event": "episode-complete",
    "customer_properties":{
        "$email":userEmail
    },
    "properties":{
        "episode":game_prefix + episode,
        "source":"web"
    }
  }
  let encodedData = base64.encode(JSON.stringify(data))
  fetch(KLAVIYO_URL + encodedData)
    .then(function (response) {
      console.log(response)
    })
    .then((data) => {
      console.log(data)
    })
    .catch(err => console.log('Error with Klaviyo', err));
}