import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import Video from './../viewer/Video'
import Image from './../viewer/Image'
import { views } from '../../_shared/utils'
import './contact.css'

const Mail = ({ vh, mail, back, trash, height, setViewed }) => {
    const [attach, setAttach] = useState(null)
    const hideAttach = () => {
        setAttach(null)
    }
    useEffect(() => {
        if (!mail.viewed) {
            setViewed(mail.id)
        }
    }, [mail.id, mail.viewed, setViewed])
    return <>
        {attach?.type === "image" && <Image icon={{ label: attach.label, url: [attach.url], vh: vh }} close={hideAttach} />}
        {attach?.type === "video" && <Video icon={{ label: attach.label, url: [attach.url] }} close={hideAttach} controls />}
        <Row className="mailContainer">
            <Col xs={12} className="mb-3 mt-2 pl-0">
                <Button className="" variant="secondary" size="lg" onClick={() => back(mail.type)}>
                    Back
                </Button>
            </Col>
            <Col xs={12} >
                <Row className="p-2 m-2 d-flex justify-content-between">
                    <span>Subject: {" "}{mail.subject}</span>
                    <span>Date, Time: {" "}{mail.dateTime}</span>
                </Row>
                <Row className="p-2 m-2" style={{ borderTop: "1px solid white" }}>
                    <span>Sender: {" "}{mail.sender}</span>
                </Row>
                <Row className="p-2 m-2" style={{ backgroundColor: 'white', border: "1px solid lightgray", height: height, overflowY: "scroll", width: "100%" }}>
                    <div style={{ width: "100%" }}>
                        <span style={{whiteSpace: "pre-line"}}>{mail.body}</span>
                        <div style={{height: "20px"}}></div>
                        <ul >
                            {mail?.attachments?.map( attachment => (
                                    <li key={attachment.label}>
                                        <a href="/" className="link-primary" onClick={(e) => {
                                        e.preventDefault(); // Prevent default behavior of anchor tag
                                        setAttach(attachment);
                                        }}>{attachment.label}</a>
                                        <p style={{whiteSpace: "pre-line"}}>{attachment.description}</p>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </Row>
                {mail.type !== views.TRASH && <Row className="p-2 m-2 float-right">
                    <Button variant="secondary" size="lg" onClick={() => trash(mail)} >
                        Trash
                    </Button>
                </Row>}
            </Col>
        </Row>
    </>
}
export default Mail