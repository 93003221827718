import React, { useContext } from 'react';
import Div100vh from 'react-div-100vh';
import Desktop from './desktop/Desktop';
import Dock from './desktop/Dock';
import Logout from './Logout';
import Login from './Login';
import { GameStateContext } from '../context/context.jsx';
import { icons } from '../_shared/config.js';

const Landing = () => {
  const context = useContext(GameStateContext);
  
  const isLoggedIn = () => {
    return true
  }
  const isEpisodeSelected = () => {
    return context?.getState()?.episode
  }
  const logout = () => {
    context?.resetState()
  }
  if(!isLoggedIn()){
      return (<Logout />)
  }
  else if (!isEpisodeSelected()){
    return (<Login />)
  }
  else 
    return (<Div100vh dontresize="true" className=" vw-100 gray-logo-background" style={{overflowY:'hidden', maxHeight:'100%'}} >
        <div style={{"border": "1px solid transparent", "height": "100%"}} id="draggable-bounds">
            <Desktop config={icons} showContact={true} /> 
        </div>
      <Dock logout={logout} />
    </Div100vh>)
  
};
export default Landing;