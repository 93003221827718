import React from 'react';
import Div100vh from 'react-div-100vh';
import Header from './common/Header';
import './css/index.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Home from './Home';
import News from './News';
import WhoWeAre from './WhoWeAre';
import TheKrews from './TheKrews';
import Sponsors from './Sponsors';
import TheBall from './TheBall';
const baseUrl = window.location.domain;
const ThreeKings = ({ hideClose, close }) => {
  return (
    <Router basename={baseUrl}>
      <Header close={close} hideClose={hideClose} />
      <Div100vh dontresize="true" className="root_container century_gothic" >
        <Switch>
          <Route exact path={["/", "/home"]} component={Home}>
          </Route>
          <Route path="/news" component={News}>
          </Route>
          <Route path="/whoweare" component={WhoWeAre}>
          </Route>
          <Route path="/theball" component={TheBall}>
          </Route>
          <Route path="/thekrewe" component={TheKrews}>
          </Route>
          <Route path="/sponsors" component={Sponsors}>
          </Route>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Div100vh>
    </Router>)
};
export default ThreeKings;