import React, { useEffect, useState, useRef, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GameStateContext } from '../../../context/context.jsx';
import config from "./assets/config";
import './login.css';

const { base_url, login: {
    login_background,
    login_logo,
    close
} } = config;

const VALID_PASSWORD = process.env.REACT_APP_VU_MAIL_DRIVE_PASS;

const Login = (props) => {
    const { getState, saveVuMailLoginStatus } = useContext(GameStateContext);
    const [loggedIn, setLoggedIn] = useState(getState()?.vuMailLoggedIn);
    const [errorMessage, setErrorMessage] = useState("");
    const [password, setPassword] = useState("");
    const passRef = useRef()

    useEffect(() => {
        errorMessage && setErrorMessage("")
    }, [password, errorMessage]);

    useEffect(() => {
        passRef?.current?.focus();
    }, [])

    const handleLogin = () => {
        if (password.toUpperCase() === VALID_PASSWORD) {
            setLoggedIn(true);
            saveVuMailLoginStatus(true)
        }
        else {
            setErrorMessage("Incorrect Password");
        }
    }

    return (<>
        {loggedIn ? <>{props.children}</> : <Container fluid className={"vu_login_container"} style={{ backgroundImage: `url(${base_url + login_background})` }} >
            <img onClick={props.close} src={base_url + close} alt={"cls_icn"} className={"close_button"} />
            <img src={base_url + login_logo} alt={"vu_icon"} className={"vu_logo"} />
            <Col lg={6} className={"vu_login_fields"}>
                <Row>
                    Log In
                </Row>
                <Row>Enter username and password</Row>
                <Row><input type={'text'} value={"CBroussard"} disabled className={"login_input"} /></Row>
                <Row><span>Hint:</span><span> Latest subject</span></Row>
                <Row><input ref={passRef} maxLength={12} onKeyUp={e => e?.key === 'Enter' && handleLogin()} placeholder={"Password"} type={"password"} value={password} onChange={e => setPassword(e.target.value)} className={"login_input"} /></Row>
                <Row>{errorMessage}</Row>
                <Row>
                    <Col><button onClick={handleLogin} className={"login_btn"}>OK</button></Col>
                    <Col><button onClick={props.close} className={"cancel_btn"}>CANCEL</button></Col>
                </Row>
            </Col>
        </Container>
        }
    </>)
}

export default Login;